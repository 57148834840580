import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Video from '../components/video'

export default function ThankYou() {
	useEffect(() => {
		const tOne = setTimeout(function () {
			document.querySelector('.masthead').classList.add('active')
		}, 500)
		const tTwo = setTimeout(function () {
			document.querySelector('.caption').classList.add('active')
		}, 1000)
		const tThree = setTimeout(function () {
			document.querySelector('.video--overlay').classList.add('active')
		}, 1750)

		return () => {
			clearTimeout(tOne)
			clearTimeout(tTwo)
			clearTimeout(tThree)
		}
	})
	return (
		<Layout noButton>
			<div className='text light p40'>
				<div className='max-450 z-2 caption balance'>
					<p className='m0 large m-mb10'>Thank you for submitting your interest.</p>
					<p className='m0 '>A member of our SIERA team will be in touch with you soon.</p>
				</div>
			</div>
			<Video video='https://player.vimeo.com/progressive_redirect/playback/776813846/rendition/1080p/file.mp4?loc=external&signature=d767fa1e12278a96f7b4fc341f9e93a0c274733364b116a01a60425cf7e56252' />
			<a href='https://sieragroup.com.au' target='_blank' rel='noreferrer' aria-label='Siera' className='sierra-logo-wrapper'>
				<div className='siera-logo' />
			</a>
		</Layout>
	)
}
